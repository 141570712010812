import Image from 'next/image';

import { Button, Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const ViewUserEventCTA = ({ eventId }) => (
  <div className='flex bg-white text-brand border border-orange py-2 rounded-lg text-sm font-medium h-8'>
    <a
      href={`/my-events/${eventId}/event-cart-details`}
      className='flex px-3'
    >
      <Image
        {...{
          src: `${staticMediaStoreBaseURL}/icons/view.svg`,
          width: 16,
          height: 16,
          alt: 'view event'
        }}
      />
      <Text {...{ content: 'Events', className: 'self-center pl-1' }} />
    </a>
  </div>
);

const CartInfo = ({
  cartName,
  cartNumber,
  eventId,
  isCartEditable = true,
  isDefaultCart,
  showEventDetail,
  toggleShowEventDetail
}) => (
  <div
    className={`planner-cart-title-bg px-3 md:px-6 pt-6 ${
      showEventDetail ? 'rounded-t-2.5 pb-4' : 'rounded-2.5 pb-6'
    }`}
  >
    <div className='flex gap-3 justify-between'>
      <div className='flex flex-col gap-2'>
        <Text
          {...{
            className:
              'font-medium text-base md:text-lg max-w-40 truncate md:max-w-full',
            content: cartName
          }}
        />
        {!isDefaultCart && isCartEditable && (
          <Button
            {...{
              children: 'View Event Details',
              className: `text-sm md:text-base text-brand font-medium mt-2 block md:hidden`,
              iconHeight: 14,
              iconPosition: 'Right',
              iconUrl: `${staticMediaStoreBaseURL}/icons/next-arrow.svg`,
              iconWidth: 14,
              imageClassName: `transform ${
                showEventDetail ? 'rotate-90' : 'rotate-0'
              }`,
              onClick: toggleShowEventDetail,
              width: 'w-auto'
            }}
          />
        )}
      </div>
      <div className='flex flex-col md:flex-row justify-end gap-2'>
        <Text
          {...{
            className:
              'text-xs md:text-base font-medium text-brand bg-light-red px-2 md:px-4 py-2 md:py-1 rounded-full h-8',
            content: `Cart No: ${cartNumber}`
          }}
        />
        {isCartEditable && eventId && <ViewUserEventCTA {...{ eventId }} />}
      </div>
    </div>
    {!isDefaultCart && isCartEditable && (
      <Button
        {...{
          children: 'View Event Details',
          className: `text-sm md:text-base text-brand font-medium mt-2 hidden md:flex`,
          iconHeight: 14,
          iconPosition: 'Right',
          iconUrl: `${staticMediaStoreBaseURL}/icons/next-arrow.svg`,
          iconWidth: 14,
          imageClassName: `transform ${
            showEventDetail ? 'rotate-90' : 'rotate-0'
          }`,
          onClick: toggleShowEventDetail,
          width: 'w-auto'
        }}
      />
    )}
  </div>
);

export default CartInfo;
