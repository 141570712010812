import Image from 'next/image';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { refreshPaymentResponse } from '@/services/payment.service';

const AcknowledgementDetails = ({ countdown, orderId }) => (
  <div className='mt-40 relative z-99 md:w-150 mx-auto px-5 md:px-0 text-white text-center'>
    <Text
      {...{
        content: 'Request Acknowledged!',
        className: 'text-xl md:text-3xl font-medium'
      }}
    />
    <Text
      {...{
        content: 'Your payment is still in-process',
        className: 'text-xl md:text-3xl font-medium'
      }}
    />
    <div className='mb-15 text-center'>
      <Image
        src={`${staticMediaStoreBaseURL}/icons/loading.gif`}
        className='mx-auto'
        width={95}
        height={80}
        alt='loading'
      />
    </div>
    <Text
      {...{
        content: `Reference Number: ${orderId}`,
        className: 'text-lightgray text-base font-light',
        HtmlTag: 'span'
      }}
    />
    <div>
      <Text
        {...{
          content:
            'Please do not refresh the page or hit back button, the page will automatically refresh the status in',
          className: 'text-lightgray text-base font-light',
          HtmlTag: 'span'
        }}
      />
      <Text
        {...{
          content: ` ${countdown} seconds`,
          className: 'text-brand text-base font-light',
          HtmlTag: 'span'
        }}
      />
    </div>
    <div className='mt-3'>
      <Text
        {...{
          content: 'For any assistance in the meantime, please call us at',
          className: 'text-lightgray text-base font-light',
          HtmlTag: 'span'
        }}
      />
      <Text
        {...{
          content: ' +97145109585',
          className: 'text-brand text-base font-light',
          HtmlTag: 'span'
        }}
      />
    </div>
  </div>
);

const SkeletonLoader = () => (
  <div className='mt-14 px-10 mx-auto absolute top-0 right-0 left-0'>
    <div className='w-full md:w-100 mx-auto'>
      <div className='w-64 mx-auto'>
        <Skeleton {...{ height: 30, width: 253 }} />
      </div>
      <div className='w-28 mx-auto my-8 md:my-12'>
        <Skeleton {...{ height: 112, width: 112 }} />
      </div>
      <div className='flex flex-col gap-2'>
        <Skeleton {...{ height: 26 }} />
        <Skeleton {...{ height: 52 }} />
      </div>
      <div className='flex flex-col gap-3 mt-8'>
        <Skeleton {...{ height: 52 }} />
        <Skeleton {...{ height: 52 }} />
      </div>
      <div className='mt-2'>
        <Skeleton {...{ height: 26 }} />
      </div>
    </div>
  </div>
);

const RELOAD_AFTER_DURATION_IN_SEC = 10;
const PaymentAcknowledgement = ({
  asPath,
  orderId,
  paymentId,
  routeToPath,
  setShowToast,
  userCartId
}) => {
  const [countdown, setCountdown] = useState(RELOAD_AFTER_DURATION_IN_SEC);
  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      refreshPaymentResponse({
        asPath,
        paymentStatusApiInput: { orderId, paymentId, userCartId },
        routeToPath,
        resetCountDownTo: RELOAD_AFTER_DURATION_IN_SEC,
        setCountdown,
        setShowToast
      });
    }
  }, [countdown]);

  return (
    <div className='overflow-hidden'>
      <div className='bg-nero fixed top-0 w-full min-h-screen z-50 opacity-80'></div>
      <AcknowledgementDetails {...{ countdown, orderId }} />
      <SkeletonLoader />
    </div>
  );
};

export default PaymentAcknowledgement;
