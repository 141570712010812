import { Text } from '@/components/atomic/atoms';
import { GetUiDeviceSurface } from '@/helpers/screenResolutionHelper';

const statusClass = {
  Collaborate: 'bg-greenlight text-green',
  Draft: 'bg-gray text-dim-gray',
  Order: 'bg-light-red text-brand'
};

const CartCardHeaderWeb = ({ cartCardTitle, cartNumber, status }) => (
  <div className='flex justify-between text-sm'>
    <Text
      {...{
        content: cartCardTitle,
        className:
          'text-base md:text-2xl overflow-ellipsis whitespace-nowrap w-150 overflow-hidden font-medium inline-block'
      }}
    />
    <div className='flex gap-4'>
      <div className='flex py-1 bg-light-red text-brand px-4 text-base justify-center rounded-full self-center'>
        No. {cartNumber}
      </div>
      <div
        className={`flex py-1 ${statusClass[status]} rounded-full px-4 text-base justify-center self-center`}
      >
        {status}
      </div>
    </div>
  </div>
);

const CartCardHeaderMobile = ({ cartCardTitle, status, cartNumber }) => (
  <div className='flex flex-col md:flex-row gap-2 md:justify-between text-sm op'>
    <div className='flex md:self-center'>
      <Text {...{ content: cartCardTitle, className: 'text-lg' }} />
    </div>
    <div className='flex gap-4'>
      <div className='flex px-4 py-2 bg-light-red text-brand text-sm rounded-full self-center h-8'>
        No. {cartNumber}
      </div>
      <div className='flex gap-4'>
        <div
          className={`flex px-4 py-2 ${statusClass[status]} text-sm rounded-full self-center h-8`}
        >
          {status}
        </div>
      </div>
    </div>
  </div>
);

const CartCardHeaderMap = {
  mobile: CartCardHeaderMobile,
  web: CartCardHeaderWeb
};

const CartCardHeader = ({ cartName, cartNumber, status }) => {
  const surface = GetUiDeviceSurface();
  const CartHeader = CartCardHeaderMap[surface];
  const cartCardTitle = cartName || 'NA';
  return (
    <CartHeader
      {...{
        cartCardTitle,
        cartNumber,
        status
      }}
    />
  );
};

export default CartCardHeader;
