import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardDateFormat, formatTimeToAmPm } from '@/lib/time';

const EventDate = ({ eventDate, eventStartTime }) => (
  <div className='flex gap-3'>
    <div className='flex self-center'>
      <Image
        {...{
          alt: 'event date',
          height: 24,
          src: `${staticMediaStoreBaseURL}/icons/calender-red.svg`,
          width: 24
        }}
      />
    </div>
    <Text
      {...{
        content:
          `${convertToStandardDateFormat(eventDate)}, ${formatTimeToAmPm(
            eventStartTime
          )}` || 'NA',
        className: 'font-medium self-center leading-relaxed'
      }}
    />
  </div>
);

const EventAddress = ({ eventAddress }) => (
  <div className='flex gap-3'>
    <div className='w-6 h-6'>
      <Image
        {...{
          alt: 'event-location',
          height: 24,
          src: `${staticMediaStoreBaseURL}/icons/location-icon.svg`,
          width: 24
        }}
      />
    </div>
    <Text
      {...{
        content: eventAddress,
        className: 'font-medium leading-relaxed flex-1 max-w-182.5 truncate'
      }}
    />
  </div>
);

const EventContactName = ({ eventContactName }) => (
  <div className='flex gap-3'>
    <div className='flex'>
      <Image
        {...{
          alt: 'event contact name',
          height: 24,
          src: `${staticMediaStoreBaseURL}/icons/user-red.svg`,
          width: 24
        }}
      />
    </div>
    <Text
      {...{
        content: eventContactName,
        className: 'font-medium leading-relaxed'
      }}
    />
  </div>
);

const HostCartEventInfo = ({
  eventAddress,
  eventContactName,
  eventDate,
  eventStartTime,
  eventTitle
}) => (
  <div className='px-3 md:px-6 pb-6 planner-cart-title-bg rounded-b-2.5'>
    <Text
      {...{
        content: eventTitle || 'NA',
        className: 'text-base md:text-lg font-medium'
      }}
    />

    <div className='flex flex-col md:flex-row text-sm md:text-base gap-4 md:gap-10 mt-4'>
      <EventContactName {...{ eventContactName }} />
      <EventDate {...{ eventDate, eventStartTime }} />
      <EventAddress {...{ eventAddress }} />
    </div>
  </div>
);

export default HostCartEventInfo;
